import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export default function Home(): JSX.Element {
  const history = useHistory();

  useEffect(() => {
    async function runAsync() {
      try {
        const response = await fetch('/login');
        history.push(response.url);
      } catch (error) {
        console.log(error);
      }
    }
    runAsync();
  }, []);

  return (
    <div />
  );
}
