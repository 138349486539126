import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { Report, getReport, executeQuery, testConnection, DataSource } from '@bcare-report/common';
import { toast } from 'react-toastify';
import { GridColDef } from '@material-ui/data-grid';
import ReportDynamicTable from '../../ReportDynamicTable';

import { useStyles } from './styles';
import { useLoading } from '../../../contexts/loading.context';

function renderColumns(data: string) {
  const result = Object.keys(data).map((key) => (
    {
      field: key,
      headerName: key,
      editable: true,
      width: 250,
    }
  ));
  return result;
}

export type ReportDynamicTableProps = {
  report?: Report;
  columns: GridColDef[];
  rows: any;
};

const defaultState: ReportDynamicTableProps = {
  report: undefined,
  columns: [],
  rows: [],
};

export default function ReportView(): JSX.Element {
  const { showLoading, hideLoading } = useLoading();
  const { id } = useParams<{ id: string }>();
  const [state, setState] = useState<ReportDynamicTableProps>(defaultState);
  const { goBack } = useHistory();

  const classes = useStyles();

  useEffect(() => {
    showLoading();
    async function runAsync() {
      try {
        const report = await getReport.execute(id);
        const connectionStatus = await testConnection.execute(report.dataSource as DataSource);

        if (connectionStatus !== 'Conectado') {
          console.log(connectionStatus);
          toast.error(`Falha ao abrir relatório - ${connectionStatus}`);
          goBack();
        } else {
          const queryResponse = await executeQuery.execute(report);
          if (queryResponse.toString() !== '') {
            const queryResponseString = JSON.stringify(queryResponse);
            const rows = JSON.parse(queryResponseString);
            const columns = renderColumns(rows[0]);
            setState({ report, columns, rows });
          } else {
            setState({ report, columns: [], rows: [] });
          }
        }
      } catch (err) {
        goBack();
        console.log(err);
      } finally {
        hideLoading();
      }
    }
    runAsync();
    return setState(defaultState);
  }, [id]);

  return (
    <Box className={classes.container}>
      <ReportDynamicTable rows={state.rows} columns={state.columns} report={state.report} />
    </Box>
  );
}
