import React from 'react';
import { MenuItem } from '@material-ui/core';
import clsx from 'clsx';

import Select, { SelectProps } from '../Select';
import { useStyles } from './styles';

type UserTypeSelectProps = Omit<SelectProps, 'children'>;

export default function UserTypeSelect({ className, ...props }: UserTypeSelectProps): JSX.Element {
  const classes = useStyles();
  return (
    <Select className={clsx(className, classes.formControl)} {...props}>
      <MenuItem value="" disabled />
      <MenuItem value="ADMIN">Admin</MenuItem>
      <MenuItem value="VIEWER">Visualização</MenuItem>
    </Select>
  );
}
