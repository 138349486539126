import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { toast } from 'react-toastify';
import { GetReportingGroups, DeleteReportGroup, ReportGroup } from '@bcare-report/common';

import { useLoading } from '../../../contexts/loading.context';
import ReportGroupTable from '../../ReportGroupTable';

import { useStyles } from './styles';

export type ReportGroupListProps = {
  getReportingGroups: GetReportingGroups;
  deleteReportGroup: DeleteReportGroup
};

type ReportGroupListState = {
  reportingGroups: ReportGroup[];
};

const defaultState: ReportGroupListState = {
  reportingGroups: [],
};

export default function ReportGroupList({ getReportingGroups, deleteReportGroup }: ReportGroupListProps): JSX.Element {
  const classes = useStyles();
  const [state, setState] = useState<ReportGroupListState>(defaultState);
  const { showLoading, hideLoading } = useLoading();

  const handleRemoveClick = useCallback((reportGroup: ReportGroup) => {
    showLoading();
    (async () => {
      try {
        await deleteReportGroup.execute({ reportGroupId: reportGroup.id });
        toast.success('Grupo removido com sucesso');
        const reportingGroups = await getReportingGroups.execute();
        setState({
          reportingGroups,
        });
      } catch (err) {
        console.log(err);
        toast.warning('Falha ao apagar grupo');
      } finally {
        hideLoading();
      }
    })();
  }, []);

  useEffect(() => {
    showLoading();
    async function runAsync() {
      try {
        const reportingGroups = await getReportingGroups.execute();
        setState({
          reportingGroups,
        });
      } catch (err) {
        console.log(err);
      } finally {
        hideLoading();
      }
    }
    runAsync();
  }, []);

  return (
    <Box className={classes.container}>
      <ReportGroupTable data={state.reportingGroups} onRemove={handleRemoveClick} />
    </Box>
  );
}
