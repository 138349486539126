import { makeStyles, Theme } from '@material-ui/core';

const drawerWidth = 240;

export const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    background: theme.palette.common.white,
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: '0px 4px 4px rgb(0,0,0,0.1)',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  brandImage: {
    height: 25,
    [theme.breakpoints.down('xs')]: {
      height: 25,
    },
    [theme.breakpoints.up('sm')]: {
      position: 'fixed',
      left: 0,
      transform: 'translateX(86px)',
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.short,
      }),
    },
  },
  brandImageShift: {
    [theme.breakpoints.up('sm')]: {
      transform: 'translateX(8px)',
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.short,
      }),
    },
  },
  button: {
    background: theme.palette.primary.light,
    padding: '14px 16px',
    textTransform: 'none',
    fontWeight: 700,
    fontSize: '1rem',
  },
  container: {
    paddingLeft: 0,
  },
  fab: {
    background: theme.palette.primary.light,
    position: 'fixed',
    bottom: 16,
    right: 16,
  },
  flex: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  navItem: {
    marginLeft: 40,
    // fontWeight: 700,
  },
  navLink: {
    color: theme.palette.text.secondary,
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.primary.light,
    },
  },
  navUserList: {
    marginRight: -theme.spacing(3),
  },
  navUserButton: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  navUserButtonText: {
    marginTop: 0,
    marginBottom: 0,
  },
  activeNavLink: {
    color: theme.palette.primary.light,
  },
  menuButton: {
    marginRight: 0,
    [theme.breakpoints.up('sm')]: {
      marginRight: 36,
    },
  },
  toolbar: {},
  hide: {
    display: 'none',
  },
}));
