import React, { createContext, useContext, useEffect, useState, ReactElement } from 'react';

export type AuthContextState = {
  isActive: boolean;
  isAdmin: boolean;
  isApproved: boolean;
  isAuthenticated: boolean;
  isViewer: boolean;
  isLoading: boolean;
  isSysadmin: boolean;
  user?: any;
};

export type AuthProviderProps = {
  children: ReactElement;
};

const defaultState: AuthContextState = {
  isActive: false,
  isAdmin: false,
  isApproved: false,
  isAuthenticated: false,
  isViewer: false,
  isLoading: true,
  isSysadmin: true,
  user: undefined,
};

export const AuthContext = createContext<AuthContextState>(defaultState);

export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const [state, setState] = useState<AuthContextState>(defaultState);

  useEffect(() => {
    async function runAsync() {
      try {
        const response = await fetch('/user');
        const user = await response.json();
        if (!user) {
          setState({
            ...defaultState,
            isLoading: false,
          });
        } else {
          setState({
            isActive: user.active,
            isAdmin: user.roles.includes('ADMIN'),
            isApproved: user.approved,
            isAuthenticated: true,
            isViewer: user.roles.includes('VIEWER'),
            isLoading: false,
            isSysadmin: user.roles.includes('SYSADMIN'),
            user,
          });
        }
      } catch (err) {
        setState({
          ...defaultState,
          isLoading: false,
        });
      }
    }
    runAsync();
  }, []);

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextState => useContext<AuthContextState>(AuthContext);
