import React, { useState } from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';

import Footer from '../../components/Footer';
// import PendingApproval from '../../components/PendingApproval';
import NavbarComposer from '../../composers/NavbarComposer';
import SidebarComposer from '../../composers/SidebarComposer';
// import { useAuth } from '../../contexts/auth.context';
import { useStyles } from './styles';

type MainLayoutProps = {
  children: JSX.Element;
};

export default function MainLayout({ children }: MainLayoutProps): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('xs'));
  const [isDrawerOpen, setIsDrawerOpen] = useState(!isXS);

  function handleDrawerOpen(): void {
    setIsDrawerOpen(true);
  }

  function handleDrawerClose(): void {
    setIsDrawerOpen(false);
  }

  return (
    <div className={classes.container}>
      <div className={classes.mainLayout}>
        <NavbarComposer isDrawerOpen={isDrawerOpen} onDrawerOpen={handleDrawerOpen} />
        <SidebarComposer isDrawerOpen={isDrawerOpen} onDrawerClose={handleDrawerClose} />
        <main className={classes.main}>
          <div className={classes.content}>{children}</div>
        </main>
      </div>
      <Footer className={classes.footer} />
    </div>
  );
}
