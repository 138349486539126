import React, { useState, ChangeEvent, useEffect } from 'react';
import { Checkbox, InputBase, TextField } from '@material-ui/core';
import { SearchSharp } from '@material-ui/icons';

import { emptyFunction } from '@bcare-report/common';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import { useStyles } from './styles';

export type GroupOption = {
  id: string;
  name: string;
}

type SearchBarProps = {
  label?: string;
  groupOptions?: GroupOption[];
  onChange?: (value: string, groupOptions: GroupOption[]) => void;
  searchStorageKey?: string;
};

export default function SearchBar({
  label,
  groupOptions,
  searchStorageKey,
  onChange = emptyFunction,
}: SearchBarProps): JSX.Element {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [groups, setGroups] = useState<GroupOption[]>([]);

  function updateStorage(_value: string, _groupOptions: GroupOption[]) {
    if (!searchStorageKey) return;
    const viewSearch = {
      searchValue: _value,
      searchGroups: _groupOptions,
    };
    localStorage.setItem(searchStorageKey, JSON.stringify(viewSearch));
  }

  function handleValueChange(e: ChangeEvent<HTMLInputElement>): void {
    setValue(e.target.value);
    onChange(e.target.value, groups);
    if (searchStorageKey) updateStorage(e.target.value, groups);
  }

  function handleGroupOptionsChange(_groupOptions: GroupOption[]): void {
    setGroups(_groupOptions);
    onChange(value, _groupOptions);
    if (searchStorageKey) updateStorage(value, _groupOptions);
  }

  useEffect(() => {
    try {
      if (!searchStorageKey) return;
      const viewSearchStr = localStorage.getItem(searchStorageKey);
      if (viewSearchStr) {
        const viewSearch = JSON.parse(viewSearchStr);
        setValue(viewSearch.searchValue);
        setGroups(viewSearch.searchGroups);
        onChange(viewSearch.searchValue, viewSearch.searchGroups);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);
  
  return (
    <div className={classes.container}>
      <div className={classes.flex}>
        <div className={classes.icon}>
          <SearchSharp />
        </div>
        <InputBase
          placeholder={label}
          value={value}
          onChange={handleValueChange}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
        />
      </div>
      <div className={clsx([classes.flex, classes.selectBoxContainer])}>
        <Autocomplete
          className={classes.selectBox}
          onChange={(event, _value) => { handleGroupOptionsChange(_value); }}
          multiple
          value={groups}
          options={groupOptions ?? []}
          getOptionLabel={(option) => option.name}
          getOptionSelected={(option, _value) => option.id === _value.id}
          disableCloseOnSelect
          size="small"
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                style={{ marginRight: 8, color: '#12C98A' }}
                checked={selected}
              />
              {option.name}
            </>
          )}
          renderInput={(params) => (
            <TextField {...params} name="reportingGroups" variant="outlined" label="Grupos" placeholder="Grupos" />
          )}
        />
      </div>
    </div>
  );
}
