import React, { useState } from 'react';
import {
  AppBar,
  Avatar,
  Toolbar,
  IconButton,
  Divider,
  Box,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import { Menu as MenuIcon, Person } from '@material-ui/icons';
import clsx from 'clsx';

import { useAuth } from '../../contexts/auth.context';
import { PASSWORD_CHANGE_ROUTE, LOGOUT_ROUTE } from '../../constants';
import { useStyles } from './styles';

type NavbarProps = {
  isDrawerOpen?: boolean;
  onDrawerOpen?: () => void;
};

export default function Navbar({ isDrawerOpen, onDrawerOpen }: NavbarProps): JSX.Element {
  const classes = useStyles();
  const [menu, setMenu] = useState(null);
  const { user } = useAuth();

  const handleMenuOpen = (event: any) => {
    setMenu(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenu(null);
  };

  const handleChangePasswordClick = () => {
    window.location.href = PASSWORD_CHANGE_ROUTE;
  };

  const handleLogoutClick = () => {
    window.location.href = LOGOUT_ROUTE;
  };

  return (
    <AppBar
      color="default"
      elevation={0}
      className={clsx(classes.appBar, {
        [classes.appBarShift]: isDrawerOpen,
      })}
    >
      <Box className={classes.container}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            onClick={onDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: isDrawerOpen,
            })}
          >
            <MenuIcon />
          </IconButton>
          <img
            src="/logo.png"
            alt="bCare | Funcional"
            className={clsx(classes.brandImage, {
              [classes.brandImageShift]: isDrawerOpen,
            })}
          />
          <div className={classes.flex}>
            <List disablePadding className={classes.navUserList}>
              <ListItem
                button
                dense
                disableGutters
                onClick={handleMenuOpen}
                className={classes.navUserButton}
                aria-haspopup="true"
                aria-controls="user-menu"
              >
                <ListItemAvatar>
                  <Avatar>
                    <Person />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={user.fullName}
                  secondary={user.email}
                  className={classes.navUserButtonText}
                />
              </ListItem>
            </List>
            <Menu
              id="user-menu"
              anchorEl={menu}
              open={Boolean(menu)}
              keepMounted
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleChangePasswordClick}>Alterar senha</MenuItem>
              <MenuItem onClick={handleLogoutClick}>Deslogar</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </Box>
      <Divider />
    </AppBar>
  );
}
