import { makeStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

export const useStyles = makeStyles(({ palette }) => ({
  wrapper: {
    position: 'relative',
  },
  button: {},
  progress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  primaryLightContained: {
    background: palette.primary.light,
  },
  primaryLightOutlined: {
    color: palette.primary.light,
    borderColor: palette.primary.light,
  },
}));
