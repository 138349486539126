import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Grid, Paper } from '@material-ui/core';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import {
  extractValidationErrors,
  GetDataSource,
  RegisterDataSource,
  TestConnection,
  ValidationError,
  dataSourceSqlServerRegisterForm,
  dataSourceAthenaRegisterForm,
} from '@bcare-report/common';

import { DATA_SOURCES_ROUTE } from '../../../constants';
import { useLoading } from '../../../contexts/loading.context';
import Button from '../../Button';
import PageTitle from '../../PageTitle';
import Input from '../../Input';
import DataSourceTypeSelect from '../../DataSourceTypeSelect';
import { useStyles } from './styles';

export type DataSourceRegisterProps = {
  getDataSource: GetDataSource;
  registerDataSource: RegisterDataSource;
  testConnection: TestConnection;
};

type DataSourceRegisterState = {
  dataSourceType: string;
};

const defaultState: DataSourceRegisterState = {
  dataSourceType: '',
};

export default function DataSourceRegister({ getDataSource, registerDataSource, testConnection }: DataSourceRegisterProps): JSX.Element {
  const classes = useStyles();
  const formRef = useRef({} as any);
  const { isLoading, showLoading, hideLoading, showBackdrop, hideBackdrop } = useLoading();
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const [dataSourceState, setDataSourceState] = useState<DataSourceRegisterState>(defaultState);

  const handleDataSourceChange = useCallback(
    (value: string) => {
      setDataSourceState({
        ...dataSourceState,
        dataSourceType: value,
      });
    },
    [dataSourceState],
  );

  const handleConnectionTest = useCallback(() => {
    showLoading();
    async function runAsync() {
      try {
        const data = formRef.current.getData();
        formRef.current.setErrors({});
        if (dataSourceState.dataSourceType === 'SQLSERVER') {
          await dataSourceSqlServerRegisterForm.validate(data, {
            abortEarly: false,
          });
        } else {
          await dataSourceAthenaRegisterForm.validate(data, {
            abortEarly: false,
          });
        }
        formRef.current.getErrors();
        const response: string = await testConnection.execute(data);
        if (response === 'Conectado') {
          toast.success('Conexão efetuada com sucesso!');
        } else {
          toast.error(`Falha na conexão: ${response}`, {
            onOpen: showBackdrop,
            onClose: hideBackdrop,
          });
        }
      } catch (err) {
        let errors = {};
        if (err instanceof ValidationError) {
          errors = extractValidationErrors(err);
          formRef.current.setErrors(errors);
        }
        console.log(err);
      } finally {
        hideLoading();
      }
    }
    runAsync();
  }, [dataSourceState]);

  const handleSubmit = useCallback(
    (data) => {
      showLoading();
      if (!data) return;
      async function runAsync() {
        try {
          formRef.current.setErrors({});
          if (dataSourceState.dataSourceType === 'SQLSERVER') {
            await dataSourceSqlServerRegisterForm.validate(data, {
              abortEarly: false,
            });
          } else {
            await dataSourceAthenaRegisterForm.validate(data, {
              abortEarly: false,
            });
          }
          await registerDataSource.execute({
            id,
            ...data,
          });
          toast.success('Sucesso!');
          push(DATA_SOURCES_ROUTE);
        } catch (err) {
          console.log(err);
          let errors = {};
          if (err instanceof ValidationError) {
            errors = extractValidationErrors(err);
            formRef.current.setErrors(errors);
          } else {
            toast.error('Ocorreu um problema ao executar a ação');
          }
        } finally {
          hideLoading();
        }
      }
      runAsync();
    }, [dataSourceState],
  );

  useEffect(() => {
    showLoading();
    async function runAsync() {
      try {
        if (id) {
          const dataSource = await getDataSource.execute(id);
          setDataSourceState({
            ...dataSourceState,
            dataSourceType: dataSource.dataSourceType,
          });
          formRef.current.setData(dataSource);
          formRef.current.getData();
        }
      } catch (err) {
        toast.error('Falha ao carregar registro');
        console.log(err);
        push(DATA_SOURCES_ROUTE);
      } finally {
        hideLoading();
      }
    }
    runAsync();
  }, [dataSourceState.dataSourceType]);

  return (
    <Box className={classes.container}>
      <PageTitle>{!id ? 'Cadastrar Fonte de Dados' : 'Editar Fonte de Dados'}</PageTitle>
      <br />
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Paper className={classes.container}>
          <Grid container spacing={2} direction="column">
            <Grid item xs={12} sm="auto">
              <Input
                name="name"
                variant="outlined"
                label="Nome da fonte de dados"
                className={classes.input}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={12} sm="auto">
              <DataSourceTypeSelect
                name="dataSourceType"
                onSelectedValueChange={handleDataSourceChange}
                variant="outlined"
                label="Tipo de conexão"
                className={classes.input}
                disabled={isLoading}
              />
            </Grid>
            {dataSourceState.dataSourceType === 'SQLSERVER' && (
              <Grid item container spacing={2} direction="column">
                <Grid item xs={12} sm="auto">
                  <Input
                    name="port"
                    variant="outlined"
                    label="Porta"
                    className={classes.input}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12} sm="auto">
                  <Input
                    name="database"
                    variant="outlined"
                    label="Nome do banco"
                    className={classes.input}
                    disabled={isLoading}
                  />
                </Grid>
              </Grid>
            )}
            {dataSourceState.dataSourceType === 'ATHENA' && (

              <Grid item xs={12} sm="auto">
                <Input
                  name="region"
                  variant="outlined"
                  label="Região"
                  className={classes.input}
                  disabled={isLoading}
                />
              </Grid>

            )}
            {dataSourceState.dataSourceType !== '' && (
              <Grid item container spacing={2} direction="column">
                <Grid item container spacing={2} direction="column">
                  <Grid item xs={12} sm="auto">
                    <Input
                      name="host"
                      variant="outlined"
                      label={dataSourceState.dataSourceType === 'SQLSERVER' ? 'Host' : 'Localização do S3'}
                      className={classes.input}
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={12} sm="auto">
                    <Input
                      name="username"
                      variant="outlined"
                      label={dataSourceState.dataSourceType === 'SQLSERVER' ? 'Usuário' : 'Acess Key'}
                      className={classes.input}
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={12} sm="auto">
                    <Input
                      name="password"
                      type="password"
                      variant="outlined"
                      autoComplete="off"
                      label={dataSourceState.dataSourceType === 'SQLSERVER' ? 'Senha' : 'Secret Key'}
                      className={classes.input}
                      disabled={isLoading}
                    />
                  </Grid>
                </Grid>
                <Grid item container spacing={2} direction="row">
                  <Button onClick={handleConnectionTest} className={classes.inlineBtn} variant="contained" color="secondary">
                    Testar Conexão
                  </Button>
                  <Button className={classes.inlineBtn} type="submit" variant="contained" color="primary">
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Form>
    </Box>
  );
}
