import React from 'react';
import { getDataSource, registerDataSource, testConnection } from '@bcare-report/common';

import DataSourceRegister, { DataSourceRegisterProps } from '../../components/DataSource/Register';

const dispatchers: DataSourceRegisterProps = {
  getDataSource,
  registerDataSource,
  testConnection,
};

export default function DataSourceRegisterComposer(): JSX.Element {
  return <DataSourceRegister {...dispatchers} />;
}
