import React from 'react';
import { User } from '@bcare-report/common';

import { USER_REGISTER_ROUTE, USER_EDIT_ROUTE } from '../../constants';
import Table from '../Table';

const columns = [
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'name',
    label: 'Nome',
  },
  {
    key: 'type',
    label: 'Perfil',
    render: function UserProfileRender(value: string) {
      if (value === 'ADMIN') {
        return 'Admin';
      }
      return 'Visualização';
    },
  },
  {
    key: 'active',
    label: 'Status',
    render: function UserActiveRender(value: boolean) {
      if (value) {
        return 'Ativo';
      }
      return 'Inativo';
    },
  },
];

export type UserTableProps = {
  data: User[];
};

export default function UserTable({ data }: UserTableProps): JSX.Element {
  return (
    <Table
      columns={columns}
      data={data}
      title="Usuários"
      size="small"
      canAdd
      addRoute={USER_REGISTER_ROUTE}
      canEdit
      editRoute={USER_EDIT_ROUTE}
    />
  );
}
