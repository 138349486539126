import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Checkbox, Chip, Grid, InputLabel, ListItemText, MenuItem, Paper, TextField } from '@material-ui/core';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import {
  extractValidationErrors,
  GetUser,
  GetReportingGroups,
  getReportGroup,
  RegisterUser,
  ValidationError,
  registerUserForm,
  editUserForm,
  ReportGroup,
} from '@bcare-report/common';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { USERS_ROUTE } from '../../../constants';
import { useLoading } from '../../../contexts/loading.context';
import Button from '../../Button';
import Input from '../../Input';
import PageTitle from '../../PageTitle';
import UserTypeSelect from '../../UserTypeSelect';

import { useStyles } from './styles';
import StatusSelect from '../../StatusSelect';

export type UserRegisterProps = {
  getReportingGroups: GetReportingGroups;
  getUser: GetUser;
  registerUser: RegisterUser;
};

type UserRegisterState = {
  reportingGroups: ReportGroup[];
  userType: string;
};

const defaultState: UserRegisterState = {
  reportingGroups: [],
  userType: '',
};

export default function UserRegister({ getUser, getReportingGroups, registerUser }: UserRegisterProps): JSX.Element {
  const classes = useStyles();
  const formRef = useRef({} as any);
  const { isLoading, showLoading, hideLoading } = useLoading();
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const [state, setState] = useState<UserRegisterState>(defaultState);
  const [reportingGroupsState, setReportingGroupsState] = useState<ReportGroup[]>([]);

  const handleUserTypeChange = useCallback(
    (value: string) => {
      setState({
        ...state,
        userType: value,
      });
    },
    [state],
  );

  const handleGroupChange = (event: any, newValue: ReportGroup[]) => {
    setReportingGroupsState(newValue);
  };

  const handleSubmit = useCallback(
    (data) => {
      showLoading();
      async function runAsync() {
        try {
          formRef.current.setErrors({});
          await editUserForm.validate(data, {
            abortEarly: false,
          });
          const reportingGroupsId = reportingGroupsState.map((x) => x.id);
          const active = (data.active === 'true');
          await registerUser.execute({
            id,
            reportingGroupsId,
            ...data,
            active,
          });
          toast.success('Registrado com sucesso');
          push(USERS_ROUTE);
        } catch (err) {
          console.log(err);
          let errors = {};
          if (err instanceof ValidationError) {
            errors = extractValidationErrors(err);
            formRef.current.setErrors(errors);
          } else {
            toast.error('Falha ao registrar usuário');
          }
        } finally {
          hideLoading();
        }
      }
      runAsync();
    },
    [id, reportingGroupsState],
  );

  useEffect(() => {
    showLoading();
    async function runAsync() {
      try {
        const reportingGroups = await getReportingGroups.execute();
        setState({
          ...state,
          reportingGroups,
        });

        if (id) {
          // Quando recebo o Obj não tenho acesso a propriedade reportingGroups
          // Quando transformo em string e depois em json, passo a ter acesso
          const userObj = await getUser.execute(id);
          const userString = JSON.stringify(userObj);
          const userJson = JSON.parse(userString);

          setReportingGroupsState(userJson.reportingGroups as ReportGroup[]);
          const fixData = {
            ...userObj,
            active: userObj.active.toString(),
          };
          formRef.current.setData(fixData);
        }
      } catch (err) {
        toast.error('Falha ao carregar usuário');
        push(USERS_ROUTE);
      } finally {
        hideLoading();
      }
    }
    runAsync();
  }, [id]);

  return (
    <Box className={classes.container}>
      <PageTitle>{!id ? 'Cadastro de usuário' : 'Editar usuário'}</PageTitle>
      <br />
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Paper className={classes.container}>
          <Grid container spacing={2} direction="column">
            <Grid item xs={12} sm="auto">
              <Autocomplete
                onChange={handleGroupChange}
                multiple
                size="small"
                value={reportingGroupsState}
                className={classes.input}
                options={state.reportingGroups}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                disableCloseOnSelect
                renderOption={(option, { selected }) => (
                  <>
                    <Checkbox
                      style={{ marginRight: 8, color: '#3793f0' }}
                      checked={selected}
                    />
                    {option.name}
                  </>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Grupos de relatórios" placeholder="Grupos" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm="auto">
              <Input
                name="name"
                variant="outlined"
                label="Nome"
                className={classes.input}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={12} sm="auto">
              <Input
                name="email"
                variant="outlined"
                label="Email"
                className={classes.input}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={12} sm="auto">
              <UserTypeSelect
                name="type"
                variant="outlined"
                label="Perfil"
                displayEmpty
                className={classes.input}
                disabled={isLoading}
                onSelectedValueChange={handleUserTypeChange}
              />
            </Grid>
            <Grid item xs={12} sm="auto">
              <StatusSelect
                name="active"
                variant="outlined"
                label="Status"
                displayEmpty
                className={classes.input}
                disabled={isLoading}
              />
            </Grid>
            <Grid item>
              <Button type="submit" variant="contained" color="primary">
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Form>
    </Box>
  );
}
