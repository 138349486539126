import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Box, Grid, Paper } from '@material-ui/core';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import {
  extractValidationErrors,
  GetReportGroup,
  RegisterReportGroup,
  ValidationError,
  reportGroupRegisterForm,
} from '@bcare-report/common';

import { REPORTING_GROUPS_ROUTE } from '../../../constants';
import { useLoading } from '../../../contexts/loading.context';
import Button from '../../Button';
import PageTitle from '../../PageTitle';
import Input from '../../Input';

import { useStyles } from './styles';

export type ReportGroupRegisterProps = {
  getReportGroup: GetReportGroup;
  registerReportGroup: RegisterReportGroup;
};

export default function ReportGroupRegister({ getReportGroup, registerReportGroup }: ReportGroupRegisterProps): JSX.Element {
  const classes = useStyles();
  const formRef = useRef({} as any);
  const { isLoading, showLoading, hideLoading } = useLoading();
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();

  const handleSubmit = useCallback(
    (data) => {
      showLoading();
      if (!data) return;
      async function runAsync() {
        try {
          formRef.current.setErrors({});
          await reportGroupRegisterForm.validate(data, {
            abortEarly: false,
          });
          await registerReportGroup.execute({
            id,
            ...data,
          });
          toast.success('Sucesso!');
          push(REPORTING_GROUPS_ROUTE);
        } catch (err) {
          console.log(err);
          let errors = {};
          if (err instanceof ValidationError) {
            errors = extractValidationErrors(err);
            formRef.current.setErrors(errors);
          } else {
            toast.error('Ocorreu um problema ao executar a ação');
          }
        } finally {
          hideLoading();
        }
      }
      runAsync();
    }, [],
  );

  useEffect(() => {
    showLoading();
    async function runAsync() {
      try {
        if (id) {
          const reportGroup = await getReportGroup.execute(id);
          formRef.current.setData(reportGroup);
        }
      } catch (err) {
        toast.error('Falha ao carregar registro');
        console.log(err);
        push(REPORTING_GROUPS_ROUTE);
      } finally {
        hideLoading();
      }
    }
    runAsync();
  }, []);

  return (
    <Box className={classes.container}>
      <PageTitle>{!id ? 'Cadastro de Grupo' : 'Editar Grupo'}</PageTitle>
      <br />
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Paper className={classes.container}>
          <Grid container spacing={2} direction="column">
            <Grid item xs={12} sm="auto">
              <Input
                name="name"
                variant="outlined"
                label="Nome do grupo"
                className={classes.input}
                disabled={isLoading}
              />
            </Grid>
            <Grid item>
              <Button type="submit" variant="contained" color="primary">
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Form>
    </Box>
  );
}
