import React from 'react';
import { getReports, deleteReport } from '@bcare-report/common';

import ReportList, { ReportListProps } from '../../components/Report/List';

const dispatchers: Pick<ReportListProps, 'getReports' | 'deleteReport'> = {
  getReports,
  deleteReport,
};

export default function ReportListComposer(): JSX.Element {
  return <ReportList {...dispatchers} />;
}
