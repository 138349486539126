import React, { useEffect, useRef, useState, ChangeEvent } from 'react';
import { useField } from '@unform/core';
import {
  FormControl,
  InputLabel,
  SelectProps as MaterialSelectProps,
  Select as MaterialSelect,
  FormHelperText,
  MenuItem,
} from '@material-ui/core';
import { emptyFunction } from '@bcare-report/common';

export type SelectProps = MaterialSelectProps & {
  label?: string;
  name: string;
  children: JSX.Element[];
  variant: 'filled' | 'outlined' | 'standard';
  onSelectedValueChange?: (value: any) => void;
};

export default function Select({
  children,
  label,
  name,
  variant,
  className,
  onSelectedValueChange = emptyFunction,
  fullWidth = false,
  placeholder,
  ...rest
}: SelectProps): JSX.Element {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [value, setValue] = useState('');
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'dataset.value',
      setValue: (_ref: any, newValue: any) => {
        setValue(newValue ? newValue.toString() : '');
      },
      clearValue: () => {
        setValue('');
      },
    });
  }, [fieldName, registerField]);
  function handleChange(event: ChangeEvent<{ name?: string; value: unknown }>): void {
    setValue(event.target.value as string);
    onSelectedValueChange(event.target.value);
  }
  return (
    <FormControl
      variant={variant}
      ref={inputRef}
      data-value={value}
      className={className}
      error={!!error}
      fullWidth={fullWidth}
      size="small"
    >
      <InputLabel>{label}</InputLabel>
      <MaterialSelect
        displayEmpty={!!placeholder}
        {...rest}
        error={!!error}
        defaultValue={defaultValue}
        value={value}
        onChange={handleChange}
        label={label}
      >
        {placeholder && (
          <MenuItem value="" disabled>
            {placeholder}
          </MenuItem>
        )}
        {children}
      </MaterialSelect>
      <FormHelperText>{error || ''}</FormHelperText>
    </FormControl>
  );
}
