import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { GetUsers, User } from '@bcare-report/common';

import { useLoading } from '../../../contexts/loading.context';
import UserTable from '../../UserTable';

import { useStyles } from './styles';

export type UserListProps = {
  getUsers: GetUsers;
};

type UserListState = {
  users: User[];
};

const defaultState: UserListState = {
  users: [],
};

export default function UserList({ getUsers }: UserListProps): JSX.Element {
  const classes = useStyles();
  const [state, setState] = useState<UserListState>(defaultState);
  const { showLoading, hideLoading } = useLoading();

  useEffect(() => {
    showLoading();
    async function runAsync() {
      try {
        const users = await getUsers.execute();
        setState({
          users,
        });
      } catch (err) {
        console.log(err);
      } finally {
        hideLoading();
      }
    }
    runAsync();
  }, []);

  return (
    <Box className={classes.container}>
      <UserTable data={state.users} />
    </Box>
  );
}
