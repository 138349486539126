// AUTH
export const SIGNIN_ROUTE = '/login';
export const SIGNUP_ROUTE = '/signup';
export const LOGOUT_ROUTE = '/logout';
export const PASSWORD_CHANGE_ROUTE = '/change-password';

// REPORTING GROUPS
export const REPORTING_GROUPS_ROUTE = '/reporting-groups';
export const REPORTING_GROUPS_REGISTER_ROUTE = '/reporting-groups/register';
export const REPORTING_GROUPS_EDIT_ROUTE = '/reporting-groups/:id/edit';
export const REPORTING_GROUPS_REMOVE_ROUTE = '/reporting-groups/:id/remove';

// USERS
export const USERS_ROUTE = '/users';
export const USER_REGISTER_ROUTE = '/users/register';
export const USER_EDIT_ROUTE = '/users/:id/edit';
export const USER_REMOVE_ROUTE = '/users/:id/remove';

// DATA SOURCES
export const DATA_SOURCES_ROUTE = '/data-sources';
export const DATA_SOURCES_REGISTER_ROUTE = '/data-sources/register';
export const DATA_SOURCES_EDIT_ROUTE = '/data-sources/:id/edit';
export const DATA_SOURCES_REMOVE_ROUTE = '/data-sources/:id/remove';

// REPORTS
export const REPORTS_ROUTE = '/reports';
export const REPORTS_REGISTER_ROUTE = '/reports/register';
export const REPORTS_EDIT_ROUTE = '/reports/:id/edit';
export const REPORTS_VIEW_ROUTE = '/reports/:id/view';
export const REPORTS_REMOVE_ROUTE = '/reports/:id/remove';

// VIEWS
export const VIEWS_ROUTE = '/views';
export const VIEWS_REGISTER_ROUTE = '/views/register';
export const VIEWS_EDIT_ROUTE = '/views/:id/edit';
export const VIEWS_VIEW_ROUTE = '/views/:id/view';
export const VIEWS_REMOVE_ROUTE = '/views/:id/remove';
