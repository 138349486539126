import React from 'react';
import { getDataSources, deleteDataSource } from '@bcare-report/common';

import DataSourceList, { DataSourceListProps } from '../../components/DataSource/List';

const dispatchers: Pick<DataSourceListProps, 'getDataSources' | 'deleteDataSource'> = {
  getDataSources,
  deleteDataSource,
};

export default function DataSourceListComposer(): JSX.Element {
  return <DataSourceList {...dispatchers} />;
}
