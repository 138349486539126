import React from 'react';
import { Route, RouteProps, RouteComponentProps } from 'react-router-dom';
import { useAuth } from '../../contexts/auth.context';

import { SIGNIN_ROUTE } from '../../constants';

type PrivateRouteProps = RouteProps & {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
};

export default function PrivateRoute({
  component: Component,
  ...rest
}: PrivateRouteProps): JSX.Element {
  const { isLoading, isAuthenticated } = useAuth();

  if (isLoading) {
    return <div style={{ backgroundColor: '#fff' }} />;
  }

  if (!isAuthenticated) {
    window.location.href = SIGNIN_ROUTE;
    return <div style={{ backgroundColor: '#fff' }} />;
  }

  return <Route {...rest} component={Component} />;

  // return (
  //   <Route
  //     {...rest}
  //     render={(props): JSX.Element => (isAuthenticated ? (
  //       <Component {...props} />
  //     ) : (
  //       <Redirect
  //         to={{
  //           pathname: SIGNIN_ROUTE,
  //           state: { from: props.location },
  //         }}
  //         strict
  //       />
  //     ))}
  //   />
  // );
}
