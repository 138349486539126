import { makeStyles, Theme, lighten } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  avatarCell: {
    maxWidth: 200,
  },
  container: {
    width: '100%',
  },
  selectableRow: {
    cursor: 'pointer',
  },
  striped: {
    '&:nth-of-type(even)': {
      background: lighten(theme.palette.action.focus, 0.5),
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(2),
  },
  title: {
    flex: 1,
  },
  errorMessage: {
    flex: 1,
    fontWeight: 'bold',
  },
  grid: {
    width: '100%',
    overflow: 'auto',
    padding: theme.spacing(1),
  },
}));
