import React from 'react';
import { getReportingGroups, deleteReportGroup } from '@bcare-report/common';

import ReportGroupList, { ReportGroupListProps } from '../../components/ReportGroup/List';

const dispatchers: Pick<ReportGroupListProps, | 'getReportingGroups' | 'deleteReportGroup'> = {
  getReportingGroups,
  deleteReportGroup,
};

export default function ReportGroupListComposer(): JSX.Element {
  return <ReportGroupList {...dispatchers} />;
}
