import { createMuiTheme } from '@material-ui/core';
import { amber } from '@material-ui/core/colors';
import { ptBR } from '@material-ui/data-grid';
import { ptBR as ptBRCore } from '@material-ui/core/locale';

export default createMuiTheme(
  {
    palette: {
      type: 'light',
      primary: {
        main: '#3793f0',
        light: '#4894e0',
      },
      secondary: {
        main: '#005fbf',
        light: '#0075eb',
      },
      warning: {
        main: amber['400'],
      },
    },
    mixins: {
      toolbar: {
        minHeight: 50,
      },
    },
    shape: {
      // borderRadius: 12,
    },
    typography: {
      fontFamily: 'Jaldi',
      h4: {
        color: '#222222',
      },
      h5: {
        color: '#222222',
      },
    },
  },
  ptBR,
  ptBRCore,
);
