import React from 'react';
import { getReportGroup, registerReportGroup } from '@bcare-report/common';

import ReportGroupRegister, { ReportGroupRegisterProps } from '../../components/ReportGroup/Register';

const dispatcher: ReportGroupRegisterProps = {
  getReportGroup,
  registerReportGroup,
};

export default function ReportGroupRegisterComposer(): JSX.Element {
  return <ReportGroupRegister {...dispatcher} />;
}
