import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    width: '100%',
    height: '100%',
  },
  input: {
    width: 300,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  chipGrid: {
    display: 'flex',
    width: 300,
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  inlineBtn: {
    marginTop: 5,
    margin: 5,
    padding: 10,
    width: 150,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    padding: theme.spacing(2),
  },
}));
