import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@material-ui/core';
import { SIGNIN_ROUTE } from '../../constants';

export default function Unauthorized(): JSX.Element {
  const handleBack = () => {
    window.location.href = SIGNIN_ROUTE;
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={3}>
        <Dialog
          open
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Você não possui permissão de acesso
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Entre em contato com os administradores do sistema.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleBack}>Voltar</Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
}
