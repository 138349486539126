"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractArrayValidationErrors = exports.extractValidationErrors = exports.ValidationError = void 0;
var yup_1 = require("yup");
Object.defineProperty(exports, "ValidationError", { enumerable: true, get: function () { return yup_1.ValidationError; } });
__exportStar(require("./forms"), exports);
var extractValidationErrors = function (err, scope) {
    if (scope === void 0) { scope = ''; }
    return err.inner.reduce(function (m, c) {
        var _a;
        return (__assign(__assign({}, m), (_a = {}, _a["" + scope + c.path] = (c.message), _a)));
    }, {});
};
exports.extractValidationErrors = extractValidationErrors;
var extractArrayValidationErrors = function (err, len) {
    var result = new Array(len);
    err.inner.forEach(function (c) {
        var _a;
        if (!c.path)
            return;
        var values = c.path.split(']');
        var index = parseInt(values[0].replace(/\D/, ''), 10);
        var path = values[1].substr(1);
        if (!result[index])
            result[index] = {};
        result[index] = __assign(__assign({}, result[index]), (_a = {}, _a[path] = (c.message), _a));
    });
    return result;
};
exports.extractArrayValidationErrors = extractArrayValidationErrors;
