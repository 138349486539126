import React, { useState } from 'react';
import { View, ReportGroup } from '@bcare-report/common';

import { VIEWS_REGISTER_ROUTE, VIEWS_EDIT_ROUTE } from '../../constants';
import Table from '../Table';

const columns = [
  {
    key: 'name',
    label: 'Nome',
  },
  {
    key: 'reportingGroups',
    label: 'Grupos vinculados',
    render: (value: ReportGroup[]) => value.map((x) => x.name).join(', '),
  },
];

export type ViewTableProps = {
  data: View[];
  onRemove: (view: View) => void;
};

type ViewTableState = {
  searchValue: string;
  searchGroups: ReportGroup[];
};

export default function ViewTable({ data, onRemove }: ViewTableProps): JSX.Element {
  const [state, setState] = useState<ViewTableState>({
    searchValue: '',
    searchGroups: [],
  });

  const onSearch = (value: string, groupOptions: ReportGroup[]) => {
    setState({
      searchValue: value,
      searchGroups: groupOptions,
    });
  };

  const getDistinctReportGroups = () => {
    let reportGroups = ([] as ReportGroup[]).concat(...data.map((x) => x.reportingGroups ?? []));
    reportGroups = reportGroups.filter((value, index, self) => index === self.findIndex((t) => (
      t.id === value.id && t.name === value.name
    )));

    return reportGroups;
  };

  const getFilteredData = () => data.filter((d) => (!state.searchValue || d.name.toLowerCase().includes(state.searchValue.toLowerCase()))
    && (state.searchGroups.length === 0 || d.reportingGroups?.map((x) => x.id).some((x) => state.searchGroups.map((s) => s.id).includes(x))));

  return (
    <Table
      columns={columns}
      data={getFilteredData()}
      title="Views"
      size="small"
      canSearch
      searchGroupOptions={getDistinctReportGroups()}
      onSearch={onSearch}
      searchStorageKey="view-search"
      canAdd
      addRoute={VIEWS_REGISTER_ROUTE}
      canEdit
      editRoute={VIEWS_EDIT_ROUTE}
      canRemove
      onRemove={onRemove}
    />
  );
}
