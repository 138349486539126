import React from 'react';
import { MenuItem } from '@material-ui/core';
import clsx from 'clsx';

import Select, { SelectProps } from '../Select';
import { useStyles } from './styles';

type StatusSelectProps = Omit<SelectProps, 'children'>;

export default function StatusSelect({ className, ...props }: StatusSelectProps): JSX.Element {
  const classes = useStyles();
  return (
    <Select className={clsx(className, classes.formControl)} {...props}>
      <MenuItem value="" disabled />
      <MenuItem value="true">Ativo</MenuItem>
      <MenuItem value="false">Inativo</MenuItem>
    </Select>
  );
}
