import React from 'react';
import { getDataSources, getReportingGroups, registerReport, getReport } from '@bcare-report/common';

import ReportRegister, { ReportRegisterProps } from '../../components/Report/Register';

const dispatchers: ReportRegisterProps = {
  getDataSources,
  getReportingGroups,
  registerReport,
  getReport,
};

export default function ReportRegisterComposer(): JSX.Element {
  return <ReportRegister {...dispatchers} />;
}
