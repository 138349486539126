import React from 'react';
import { getViews, deleteView } from '@bcare-report/common';

import ViewList, { ViewListProps } from '../../components/TableauView/List';

const dispatchers: Pick<ViewListProps, 'getViews' | 'deleteView'> = {
  getViews,
  deleteView,
};

export default function TableauViewListComposer(): JSX.Element {
  return <ViewList {...dispatchers} />;
}
