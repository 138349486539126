import React from 'react';
import { Typography, TypographyProps } from '@material-ui/core';

import { useStyles } from './styles';

export default function PageTitle(props: TypographyProps): JSX.Element {
  const classes = useStyles();

  return <Typography variant="h4" className={classes.title} {...props} />;
}
