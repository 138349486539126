import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  content: {
    flex: 1,
  },
  flex: {
    flex: 1,
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  mainLayout: {
    background: theme.palette.grey[200],
    display: 'flex',
    height: 'calc(100vh - 25px)',
  },
  main: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflowX: 'hidden',
    marginTop: 50,
    [theme.breakpoints.up('sm')]: {
      marginTop: 50,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    height: 72,
    [theme.breakpoints.up('sm')]: {
      height: 100,
    },
  },
}));
