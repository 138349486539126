import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { toast } from 'react-toastify';
import { GetReports, DeleteReport, Report } from '@bcare-report/common';
import ReportTable from '../../ReportTable';

import { useLoading } from '../../../contexts/loading.context';

import { useStyles } from './styles';

export type ReportListProps = {
  getReports: GetReports;
  deleteReport: DeleteReport
};

type ReportListState = {
  reports: Report[];
};

const defaultState: ReportListState = {
  reports: [],
};

export default function ReportList({ getReports, deleteReport }: ReportListProps): JSX.Element {
  const classes = useStyles();
  const [state, setState] = useState<ReportListState>(defaultState);
  const { showLoading, hideLoading } = useLoading();

  const handleRemoveClick = useCallback((report: Report) => {
    showLoading();
    (async () => {
      try {
        await deleteReport.execute(report.id);
        toast.success('Relatório removido com sucesso');
        const reports = await getReports.execute();
        setState({
          reports,
        });
      } catch (err) {
        console.log(err);
        toast.warning('Falha ao excluir relatório');
      } finally {
        hideLoading();
      }
    })();
  }, []);

  useEffect(() => {
    showLoading();
    async function runAsync() {
      try {
        const reports = await getReports.execute();
        setState({
          reports,
        });
      } catch (err) {
        console.log(err);
      } finally {
        hideLoading();
      }
    }
    runAsync();
  }, []);

  return (
    <Box className={classes.container}>
      <ReportTable data={state.reports} onRemove={handleRemoveClick} />
    </Box>
  );
}
