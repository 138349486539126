import React, { useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import { TextField, TextFieldProps } from '@material-ui/core';

export type InputProps = TextFieldProps & {
  name: string;
  [key: string]: any;
};

export default function Input({ name, helperText, ...rest }: InputProps): JSX.Element {
  const inputRef = useRef(null);
  const textRef = useRef(null as any);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [value, setValue] = useState();
  const handleChange = (e: any): void => {
    setValue(e.target.value);
  };
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue: (ref, newValue: any) => {
        setValue(newValue);
        if (ref && newValue) {
          // eslint-disable-next-line no-param-reassign
          ref.value = newValue;
        }
      },
    });
  }, [fieldName, registerField]);
  return (
    <TextField
      error={!!error}
      helperText={error || helperText}
      defaultValue={defaultValue}
      ref={textRef}
      {...rest}
      inputRef={inputRef}
      name={name}
      InputLabelProps={{
        shrink: !!value,
      }}
      onChange={handleChange}
      value={value}
      size="small"
    />
  );
}
