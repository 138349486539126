import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import {
  Table as TableComponent,
  TableBody,
  TableContainer,
  TablePagination,
  TableRow,
  TableHead,
  TableCell,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  Paper,
  Typography,
  Avatar,
  Radio,
  TableRowProps,
} from '@material-ui/core';
import { AddBox, Edit, Check, Close, Visibility } from '@material-ui/icons';
import { emptyFunction } from '@bcare-report/common';
import ExportToExcel from '../ExportToExcel';
import { useStyles } from './styles';
import SearchBar, { GroupOption } from '../SearchBar';

export type TableColumn = {
  avatar?: boolean;
  avatarFallback?: string;
  currency?: boolean;
  key: string;
  label: string;
  numeric?: boolean;
  render?: (value: any) => JSX.Element | string;
};

type TableProps = {
  addRoute?: string;
  canAdd?: boolean;
  canExport?: boolean;
  canApprove?: boolean;
  canEdit?: boolean;
  canView?: boolean;
  canRemove?: boolean;
  canSearch?: boolean;
  canSelect?: boolean;
  columns: TableColumn[];
  data: ReadonlyArray<any>;
  editRoute?: string;
  viewRoute?:string;
  elevation?: number;
  loading?: boolean;
  onApprove?: (item: any) => void;
  onRemove?: (item: any) => void;
  onSearch?: (value: string, groupOptions: GroupOption[]) => void;
  onSelect?: (item: any) => void;
  size?: 'small' | 'medium';
  striped?: boolean;
  title?: string;
  searchGroupOptions?: GroupOption[];
  searchStorageKey?: string;
};

type TableState = {
  page: number;
  approvalDialogItem: any;
  removalDialogItem: any;
  rowsPerPage: number;
};

export default function Table({
  addRoute,
  canAdd,
  canExport,
  canApprove,
  canEdit,
  canView,
  canRemove,
  canSearch,
  canSelect,
  columns,
  data,
  editRoute,
  viewRoute,
  elevation = 1,
  loading,
  onApprove = emptyFunction,
  onRemove = emptyFunction,
  onSearch = emptyFunction,
  onSelect = emptyFunction,
  size = 'medium',
  striped = true,
  title,
  searchGroupOptions = [],
  searchStorageKey,
}: TableProps): JSX.Element {
  const classes = useStyles();
  const [state, setState] = useState<TableState>({
    page: 0,
    approvalDialogItem: undefined,
    removalDialogItem: undefined,
    rowsPerPage: 5,
  });
  const [selected, setSelected] = useState<any>();

  useEffect(() => {
    if (selected) {
      setSelected(undefined);
    }
  }, [data]);

  function handlePageChange(_event: any, page: number): void {
    setState({
      ...state,
      page,
    });
  }

  function handleRowsPerPageChange(event: any): void {
    setState({
      ...state,
      page: 0,
      rowsPerPage: +event.target.value,
    });
  }

  function handleApprove(item: any): void {
    setState({
      ...state,
      approvalDialogItem: item,
    });
  }

  function handleApprovalConfirm(): void {
    onApprove(state.approvalDialogItem);
    setState({
      ...state,
      approvalDialogItem: undefined,
    });
  }

  function handleApprovalDialogClose(): void {
    setState({
      ...state,
      approvalDialogItem: undefined,
    });
  }

  function handleRemove(item: any): void {
    setState({
      ...state,
      removalDialogItem: item,
    });
  }

  function handleRemovalConfirm(): void {
    onRemove(state.removalDialogItem);
    setState({
      ...state,
      removalDialogItem: undefined,
    });
  }

  function handleRemovalDialogClose(): void {
    setState({
      ...state,
      removalDialogItem: undefined,
    });
  }

  function handleSelect(item: any): TableRowProps['onClick'] {
    return function handleItemSelect(): void {
      setSelected(item);
      onSelect(item);
    };
  }

  function renderHead(): JSX.Element[] {
    let cells: JSX.Element[] = [];
    cells = [
      ...cells,
      ...columns.map((c) => (
        <TableCell
          key={c.key}
          align={c.numeric || c.currency ? 'right' : 'left'}
          className={classes.avatarCell}
        >
          {c.label}
        </TableCell>
      )),
    ];
    if (canEdit) {
      cells = [...cells, <TableCell key="actions" />];
    }
    if (canView) {
      cells = [...cells, <TableCell key="actions" />];
    }
    return cells;
  }

  function renderBody(): JSX.Element[] {
    const { page, rowsPerPage } = state;
    return data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((d: any) => (
      <TableRow
        hover={canSelect}
        key={`tablerow[${d.id}]`}
        className={clsx({
          [classes.striped]: striped,
          [classes.selectableRow]: canSelect,
        })}
        onClick={handleSelect(d)}
      >
        {canSelect && (
          <TableCell key={`tablerow[${d.id}][check]`} align="center" padding="checkbox">
            <Radio checked={d === selected} />
          </TableCell>
        )}
        {columns.map((c: TableColumn) => (
          <TableCell
            key={`tablerow[${d.id}][${c.key}]`}
            className={clsx({ [classes.avatarCell]: c.avatar })}
          >
            {c.avatar ? (
              <Avatar src={d[c.key]}>
                {!d[c.key] && c.avatarFallback !== undefined && d[c.avatarFallback]
                  ? d[c.avatarFallback].substring(0, 2).toUpperCase()
                  : ''}
              </Avatar>
            ) : c.render ? (
              c.render(d[c.key])
            ) : (
              d[c.key]
            )}
          </TableCell>
        ))}
        {((canEdit && editRoute) || (canRemove && onRemove)) && (
          <TableCell key={`tablerow[${d.id}][actions]`} align="right">
            {canApprove && onApprove && !d.approved && (
              <IconButton aria-label="approve" onClick={(): void => handleApprove(d)}>
                <Check />
              </IconButton>
            )}
            {canEdit && editRoute && (
              <IconButton aria-label="edit" component={Link} to={editRoute.replace(':id', d.id)}>
                <Edit />
              </IconButton>
            )}
            {canRemove && onRemove && (
              <IconButton aria-label="remove" onClick={(): void => handleRemove(d)}>
                <Close />
              </IconButton>
            )}
          </TableCell>
        )}
        {(canView && viewRoute) && (
          <TableCell key={`tablerow[${d.id}][actions]`} align="right">
            {canView && viewRoute && (
              <IconButton aria-label="edit" component={Link} to={viewRoute.replace(':id', d.id)}>
                <Visibility />
              </IconButton>
            )}
          </TableCell>
        )}
      </TableRow>
    ));
  }

  const { page, rowsPerPage, approvalDialogItem, removalDialogItem } = state;
  return (
    <Paper className={classes.container} elevation={elevation}>
      <div className={clsx([classes.titleContainer, canSearch ? classes.titleContainerWithSearch : ''])}>
        {!!title && (
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
        )}
        {canSearch && <SearchBar searchStorageKey={searchStorageKey} onChange={onSearch} groupOptions={searchGroupOptions} label="Pesquisar" />}
        {canAdd && addRoute && (
          <Button
            className={classes.addButton}
            component={Link}
            variant="contained"
            color="primary"
            startIcon={<AddBox />}
            to={addRoute}
          >
            Incluir
          </Button>
        )}
        {canExport && (
          <ExportToExcel data={data} fileName={title || 'Relatorio'} />
        )}
      </div>
      <TableContainer>
        <TableComponent size={size}>
          <TableHead>
            <TableRow>{renderHead()}</TableRow>
          </TableHead>
          <TableBody>{renderBody()}</TableBody>
        </TableComponent>
        <TablePagination
          component="div"
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 50, 100]}
          count={data.length}
          colSpan={columns.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
        />
      </TableContainer>
      <Dialog
        open={!!approvalDialogItem}
        onClose={handleApprovalDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-approval">Confirmar aprovação</DialogTitle>
        <DialogActions>
          <Button onClick={handleApprovalDialogClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleApprovalConfirm} color="primary" autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={!!removalDialogItem}
        onClose={handleRemovalDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmar</DialogTitle>
        <DialogActions>
          <Button onClick={handleRemovalDialogClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleRemovalConfirm} color="primary" autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
