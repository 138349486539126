import React from 'react';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './styles';

export type FooterProps = {
  className?: string;
};

export default function Footer({ className }: FooterProps): JSX.Element {
  const classes = useStyles();

  return (
    <div className={clsx(classes.footer, className)}>
      <Typography variant="caption" className={classes.span}>
        © 2021
        {' '}
        <a href="https://www.bcare.com.br" target="_blank" rel="noreferrer">
          bCare
        </a>
        . Todos os direitos reservados.
      </Typography>
    </div>
  );
}
