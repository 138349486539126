import React from 'react';
import { Report } from '@bcare-report/common';
import { GridColDef } from '@material-ui/data-grid';
import DataGrid from '../DataGrid';

export type ReportGroupTableProps = {
  report?: Report;
  columns: GridColDef[];
  rows: any;
};

export default function ReportDynamicTable({ rows, columns, report }: ReportGroupTableProps): JSX.Element {
  return (
    <DataGrid
      title={report?.name}
      columns={columns}
      rows={rows}
      canExport
    />
  );
}
