import React from 'react';
import { getUsers } from '@bcare-report/common';

import UserList, { UserListProps } from '../../components/User/List';

const dispatchers: Pick<UserListProps, 'getUsers'> = {
  getUsers,
};

export default function UserListComposer(): JSX.Element {
  return <UserList {...dispatchers} />;
}
