/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Drawer,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
  Divider,
  List,
  Tooltip,
  useMediaQuery,
  Icon,
  Collapse,
  Typography,
} from '@material-ui/core';
import { Person, FileCopy, FolderShared, ListAlt, LinearScale, Assessment, ChevronLeft, ChevronRight, ExpandLess, ExpandMore, Settings, PieChart } from '@material-ui/icons';
import clsx from 'clsx';

import { getReportsByUser, Report, View, getViewsByUser } from '@bcare-report/common';

import { USERS_ROUTE, REPORTING_GROUPS_ROUTE, REPORTS_ROUTE, DATA_SOURCES_ROUTE, REPORTS_VIEW_ROUTE, VIEWS_ROUTE, VIEWS_VIEW_ROUTE } from '../../constants';
import { useStyles } from './styles';
import { useAuth } from '../../contexts/auth.context';

type SidebarProps = {
  isDrawerOpen?: boolean;
  onDrawerClose?: () => void;
};

type openCloseState = {
  configCollapse: boolean;
  reportCollapse: boolean;
  viewCollapse: boolean;
};

const defaultState: openCloseState = {
  configCollapse: false,
  reportCollapse: false,
  viewCollapse: false,
};

const sysadminRoutes = [
  {
    icon: ListAlt,
    label: 'Grupos de Relatórios',
    route: REPORTING_GROUPS_ROUTE,
  },
  {
    icon: Person,
    label: 'Usuários',
    route: USERS_ROUTE,
  },
  {
    icon: LinearScale,
    label: 'Fontes de Dados',
    route: DATA_SOURCES_ROUTE,
  },
  {
    icon: Assessment,
    label: 'Cadastrar Relatórios',
    route: REPORTS_ROUTE,
  },
  {
    icon: PieChart,
    label: 'Cadastrar Views',
    route: VIEWS_ROUTE,
  },
];

export default function Sidebar({ isDrawerOpen, onDrawerClose }: SidebarProps): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('xs'));
  const { pathname } = useLocation();
  const [open, setOpen] = useState<openCloseState>(defaultState);
  const [reports, setReports] = useState<Report[]>([]);
  const [views, setViews] = useState<View[]>([]);
  const { user, isAdmin } = useAuth();

  const routes = sysadminRoutes;

  const handleConfigClick = () => {
    const configCollapse = !open.configCollapse;
    setOpen({ ...open, configCollapse });
  };

  const handleReportClick = async () => {
    const reportCollapse = !open.reportCollapse;
    setOpen({ ...open, reportCollapse });
  };

  const handleViewClick = async () => {
    const viewCollapse = !open.viewCollapse;
    setOpen({ ...open, viewCollapse });
  };

  useEffect(() => {
    async function runAsync() {
      try {
        const reportsByUser = await getReportsByUser.execute(user.userId);
        setReports(reportsByUser);

        const viewsByUser = await getViewsByUser.execute(user.userId);
        setViews(viewsByUser);
      } catch (err) {
        console.log(err);
      }
    }
    runAsync();
  }, []);

  return (
    <Drawer
      variant={isXS ? 'temporary' : 'permanent'}
      open={isDrawerOpen}
      ModalProps={{
        keepMounted: true,
      }}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: isDrawerOpen,
        [classes.drawerClose]: !isDrawerOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: isDrawerOpen,
          [classes.drawerClose]: !isDrawerOpen,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={onDrawerClose}>
          {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
      </div>
      <Divider />
      <div className={classes.list}>
        {isAdmin && (
        <>
          <ListItem button onClick={handleConfigClick}>
            <ListItemIcon>
              <Settings color="primary" />
            </ListItemIcon>
            <ListItemText className={classes.listItemTitleText} primary="Configurações" />
            {open.configCollapse ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open.configCollapse} timeout="auto" unmountOnExit>
            <List>
              {routes.map(({ icon: Icon, label, route }) => (
                <Tooltip
                  title={label}
                  key={label}
                  placement="right"
                  arrow
                  disableHoverListener={isDrawerOpen}
                >
                  <ListItem
                    button
                    className={classes.drawerListItem}
                    component={Link}
                    to={route}
                  >
                    <ListItemIcon>
                      <Icon style={pathname === route ? { color: '#23A678' } : { color: 'inherit' }} />
                    </ListItemIcon>
                    <ListItemText
                      style={pathname === route ? { color: '#23A678' } : { color: 'inherit' }}
                      primary={label}
                      hidden={!isDrawerOpen}
                    />
                  </ListItem>
                </Tooltip>
              ))}
            </List>
          </Collapse>
        </>
        )}
        <ListItem button onClick={handleReportClick}>
          <ListItemIcon>
            <FolderShared color="primary" />
          </ListItemIcon>
          <ListItemText className={classes.listItemTitleText} primary="Meus Relatórios" />
          {open.viewCollapse ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open.reportCollapse} timeout="auto" unmountOnExit>
          <List>
            {reports.map((report, i) => (
              <>
                <Tooltip
                  title="Meus relatórios"
                  key={i}
                  placement="right"
                  arrow
                  disableHoverListener={isDrawerOpen}
                >
                  <ListItem
                    button
                    className={classes.drawerListItem}
                    component={Link}
                    to={REPORTS_VIEW_ROUTE.replace(':id', report.id)}
                  >
                    <ListItemIcon>
                      <FileCopy style={pathname === REPORTS_VIEW_ROUTE.replace(':id', report.id) ? { color: '#23A678' } : { color: 'inherit' }} />
                      <Icon style={pathname === REPORTS_VIEW_ROUTE.replace(':id', report.id) ? { color: '#23A678' } : { color: 'inherit' }} />
                    </ListItemIcon>
                    <ListItemText
                      style={pathname === REPORTS_VIEW_ROUTE.replace(':id', report.id) ? { color: '#23A678' } : { color: 'inherit' }}
                      className={classes.listItemText}
                      primary={report.name}
                      hidden={!isDrawerOpen}
                    />
                  </ListItem>
                </Tooltip>
              </>
            ))}
          </List>
        </Collapse>
        <ListItem button onClick={handleViewClick}>
          <ListItemIcon>
            <FolderShared color="primary" />
          </ListItemIcon>
          <ListItemText className={classes.listItemTitleText} primary="Minhas Views" />
          {open.viewCollapse ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open.viewCollapse} timeout="auto" unmountOnExit>
          <List>
            {views.map((view, i) => (
              <>
                <Tooltip
                  title="Minhas Views"
                  key={i}
                  placement="right"
                  arrow
                  disableHoverListener={isDrawerOpen}
                >
                  <ListItem
                    button
                    className={classes.drawerListItem}
                    component={Link}
                    to={VIEWS_VIEW_ROUTE.replace(':id', view.id)}
                  >
                    <ListItemIcon>
                      <FileCopy style={pathname === VIEWS_VIEW_ROUTE.replace(':id', view.id) ? { color: '#23A678' } : { color: 'inherit' }} />
                      <Icon style={pathname === VIEWS_VIEW_ROUTE.replace(':id', view.id) ? { color: '#23A678' } : { color: 'inherit' }} />
                    </ListItemIcon>
                    <ListItemText
                      style={pathname === VIEWS_VIEW_ROUTE.replace(':id', view.id) ? { color: '#23A678' } : { color: 'inherit' }}
                      className={classes.listItemText}
                      primary={view.name}
                      hidden={!isDrawerOpen}
                    />
                  </ListItem>
                </Tooltip>
              </>
            ))}
          </List>
        </Collapse>
      </div>
      <Divider />
    </Drawer>
  );
}
