import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Checkbox, Grid, MenuItem, Paper, TextField } from '@material-ui/core';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import {
  extractValidationErrors,
  GetReportingGroups,
  ReportGroup,
  RegisterView,
  ValidationError,
  viewRegisterForm,
  GetView,
} from '@bcare-report/common';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '../../Select';
import { VIEWS_ROUTE } from '../../../constants';
import { useLoading } from '../../../contexts/loading.context';
import Button from '../../Button';
import Input from '../../Input';
import PageTitle from '../../PageTitle';

import { useStyles } from './styles';

export type ViewRegisterProps = {
  getReportingGroups: GetReportingGroups;
  registerView: RegisterView;
  getView: GetView;
};

type ViewRegisterState = {
  reportingGroups: ReportGroup[];
};

const defaultState: ViewRegisterState = {
  reportingGroups: [],
};

export default function ViewRegister({ getReportingGroups, registerView, getView }: ViewRegisterProps): JSX.Element {
  const classes = useStyles();
  const formRef = useRef({} as any);
  const { isLoading, showLoading, hideLoading } = useLoading();
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const [state, setState] = useState<ViewRegisterState>(defaultState);
  const [reportingGroupsState, setReportingGroupsState] = useState<ReportGroup[]>([]);

  const handleGroupChange = (event: any, newValue: ReportGroup[]) => {
    setReportingGroupsState(newValue);
  };

  const handleSubmit = useCallback(
    (data) => {
      showLoading();
      async function runAsync() {
        try {
          formRef.current.setErrors({});
          await viewRegisterForm.validate(data, {
            abortEarly: false,
          });
          const reportingGroupsId = reportingGroupsState.map((x) => x.id);
          await registerView.execute({
            id,
            reportingGroupsId,
            ...data,
          });

          toast.success('Registrado com sucesso');
          push(VIEWS_ROUTE);
        } catch (err) {
          console.log(err);
          let errors = {};
          if (err instanceof ValidationError) {
            errors = extractValidationErrors(err);
            formRef.current.setErrors(errors);
          } else {
            toast.error('Falha ao registrar view');
          }
        } finally {
          hideLoading();
        }
      }
      runAsync();
    },
    [id, reportingGroupsState],
  );

  useEffect(() => {
    showLoading();
    async function runAsync() {
      try {
        const reportingGroups = await getReportingGroups.execute();

        setState({
          ...state,
          reportingGroups,
        });

        if (id) {
          const view = await getView.execute(id);

          setReportingGroupsState(view.reportingGroups as ReportGroup[]);
          const fixData = {
            ...view,
          };
          formRef.current.setData(fixData);
        }
      } catch (err) {
        toast.error('Falha ao carregar view');
        push(VIEWS_ROUTE);
      } finally {
        hideLoading();
      }
    }
    runAsync();
  }, [id]);

  return (
    <Box className={classes.container}>
      <PageTitle>{!id ? 'Cadastro de View' : 'Editar View'}</PageTitle>
      <br />
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Paper className={classes.container}>
          <Grid container spacing={2} direction="column">
            <Grid item xs={12} sm="auto">
              <Input
                name="name"
                variant="outlined"
                label="Nome da view"
                className={classes.input}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={12} sm="auto">
              <Autocomplete
                onChange={handleGroupChange}
                multiple
                value={reportingGroupsState}
                className={classes.input}
                options={state.reportingGroups}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                disableCloseOnSelect
                size="small"
                renderOption={(option, { selected }) => (
                  <>
                    <Checkbox
                      style={{ marginRight: 8, color: '#12C98A' }}
                      checked={selected}
                    />
                    {option.name}
                  </>
                )}
                renderInput={(params) => (
                  <TextField {...params} name="reportingGroups" required={reportingGroupsState.length < 1} variant="outlined" label="Grupos de relatórios" placeholder="Grupos" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm="auto">
              <Input
                name="username"
                variant="outlined"
                label="Usuário do Tableau"
                className={classes.input}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={12} sm="auto">
              <Input
                name="targetSite"
                variant="outlined"
                label="Target Site do Tableau"
                className={classes.input}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={12} sm="auto">
              <Input
                name="url"
                multiline
                rows={5}
                variant="outlined"
                label="Url Tableau"
                fullWidth
                disabled={isLoading}
              />
            </Grid>
            <Grid item>
              <Button type="submit" variant="contained" color="primary">
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Form>
    </Box>
  );
}
