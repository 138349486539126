import React, { memo } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { MainLayout } from '../layouts';
import PrivateRoute from '../components/PrivateRoute';
import { useAuth } from '../contexts/auth.context';
import {
  REPORTING_GROUPS_ROUTE,
  REPORTING_GROUPS_REGISTER_ROUTE,
  REPORTING_GROUPS_EDIT_ROUTE,
  USERS_ROUTE,
  USER_REGISTER_ROUTE,
  USER_EDIT_ROUTE,
  SIGNIN_ROUTE,
  DATA_SOURCES_ROUTE,
  DATA_SOURCES_REGISTER_ROUTE,
  DATA_SOURCES_EDIT_ROUTE,
  REPORTS_ROUTE,
  REPORTS_REGISTER_ROUTE,
  REPORTS_EDIT_ROUTE,
  REPORTS_VIEW_ROUTE,
  VIEWS_ROUTE,
  VIEWS_EDIT_ROUTE,
  VIEWS_REGISTER_ROUTE,
  VIEWS_VIEW_ROUTE,
} from '../constants';

import HomePage from '../pages/home';
import ReportingGroupsListPage from '../pages/reportingGroups/list';
import ReportingGroupsRegisterPage from '../pages/reportingGroups/register';
import UsersListPage from '../pages/users/list';
import UsersRegisterPage from '../pages/users/register';
import DataSourceListPage from '../pages/dataSources/list';
import DataSourceRegisterPage from '../pages/dataSources/register';
import ReportListPage from '../pages/report/list';
import ReportRegisterPage from '../pages/report/register';
import ReportViewPage from '../pages/report/view';
import ViewListPage from '../pages/TableauView/list';
import ViewRegisterPage from '../pages/TableauView/register';
import TableauViewPage from '../pages/TableauView/view';
import UnauthorizedPage from '../pages/unauthorized';

function Routes(): JSX.Element {
  const { isAdmin, isViewer, isAuthenticated, isLoading } = useAuth();

  if (isLoading) {
    return <div />;
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path={SIGNIN_ROUTE} exact component={HomePage} />
        {!isAuthenticated ? (
          [
            <Route key="route_home" path="/" exact component={HomePage} />,
            <Route key="route_unauthorized" path="/unauthorized" exact component={UnauthorizedPage} />,
            <PrivateRoute key="private_route_redirect" path="/*" component={React.Fragment} />,
          ]
        ) : (
          <PrivateRoute path="/" component={React.Fragment}>
            <MainLayout>
              <Switch>
                {isAdmin && [
                  <PrivateRoute
                    key={REPORTING_GROUPS_ROUTE}
                    path={REPORTING_GROUPS_ROUTE}
                    exact
                    component={ReportingGroupsListPage}
                  />,
                  <PrivateRoute
                    key={REPORTING_GROUPS_REGISTER_ROUTE}
                    path={REPORTING_GROUPS_REGISTER_ROUTE}
                    exact
                    component={ReportingGroupsRegisterPage}
                  />,
                  <PrivateRoute
                    key={REPORTING_GROUPS_EDIT_ROUTE}
                    path={REPORTING_GROUPS_EDIT_ROUTE}
                    exact
                    component={ReportingGroupsRegisterPage}
                  />,
                  <PrivateRoute
                    key={USERS_ROUTE}
                    path={USERS_ROUTE}
                    exact
                    component={UsersListPage}
                  />,
                  <PrivateRoute
                    key={USER_REGISTER_ROUTE}
                    path={USER_REGISTER_ROUTE}
                    exact
                    component={UsersRegisterPage}
                  />,
                  <PrivateRoute
                    key={USER_EDIT_ROUTE}
                    path={USER_EDIT_ROUTE}
                    exact
                    component={UsersRegisterPage}
                  />,
                  <PrivateRoute
                    key={DATA_SOURCES_ROUTE}
                    path={DATA_SOURCES_ROUTE}
                    exact
                    component={DataSourceListPage}
                  />,
                  <PrivateRoute
                    key={DATA_SOURCES_REGISTER_ROUTE}
                    path={DATA_SOURCES_REGISTER_ROUTE}
                    exact
                    component={DataSourceRegisterPage}
                  />,
                  <PrivateRoute
                    key={DATA_SOURCES_EDIT_ROUTE}
                    path={DATA_SOURCES_EDIT_ROUTE}
                    exact
                    component={DataSourceRegisterPage}
                  />,
                  <PrivateRoute
                    key={REPORTS_ROUTE}
                    path={REPORTS_ROUTE}
                    exact
                    component={ReportListPage}
                  />,
                  <PrivateRoute
                    key={REPORTS_REGISTER_ROUTE}
                    path={REPORTS_REGISTER_ROUTE}
                    exact
                    component={ReportRegisterPage}
                  />,
                  <PrivateRoute
                    key={REPORTS_EDIT_ROUTE}
                    path={REPORTS_EDIT_ROUTE}
                    exact
                    component={ReportRegisterPage}
                  />,
                  <PrivateRoute
                    key={VIEWS_ROUTE}
                    path={VIEWS_ROUTE}
                    exact
                    component={ViewListPage}
                  />,
                  <PrivateRoute
                    key={VIEWS_REGISTER_ROUTE}
                    path={VIEWS_REGISTER_ROUTE}
                    exact
                    component={ViewRegisterPage}
                  />,
                  <PrivateRoute
                    key={VIEWS_EDIT_ROUTE}
                    path={VIEWS_EDIT_ROUTE}
                    exact
                    component={ViewRegisterPage}
                  />,
                ]}
                {(isAdmin || isViewer) && [
                  <PrivateRoute key={REPORTS_VIEW_ROUTE} path={REPORTS_VIEW_ROUTE} exact component={ReportViewPage} />,
                  <PrivateRoute key={VIEWS_VIEW_ROUTE} path={VIEWS_VIEW_ROUTE} exact component={TableauViewPage} />,
                ]}
              </Switch>
            </MainLayout>
          </PrivateRoute>
        )}
      </Switch>
    </BrowserRouter>
  );
}

export default memo(Routes);
