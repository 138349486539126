import React from 'react';
import {
  Button as MaterialButton,
  ButtonProps as MaterialButtonProps,
  CircularProgress,
} from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './styles';

export type ButtonProps = MaterialButtonProps & {
  loading?: boolean;
  shade?: 'light';
};

export default function Button({
  loading,
  disabled,
  className,
  variant,
  color,
  shade,
  ...props
}: ButtonProps): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <MaterialButton
        {...props}
        variant={variant}
        color={color}
        disabled={disabled || loading}
        className={clsx(classes.button, className, {
          [classes.primaryLightContained]:
            variant === 'contained' && color === 'primary' && shade === 'light',
          [classes.primaryLightOutlined]:
            variant === 'outlined' && color === 'primary' && shade === 'light',
        })}
      />
      {loading && <CircularProgress size={24} className={classes.progress} />}
    </div>
  );
}
