import React from 'react';
import { ReportGroup } from '@bcare-report/common';

import { REPORTING_GROUPS_REGISTER_ROUTE, REPORTING_GROUPS_EDIT_ROUTE } from '../../constants';
import Table from '../Table';

const columns = [
  {
    key: 'name',
    label: 'Nome',
  },
];

export type ReportGroupTableProps = {
  data: ReportGroup[];
  onRemove: (group: ReportGroup) => void;
};

export default function ReportGroupTable({ data, onRemove }: ReportGroupTableProps): JSX.Element {
  return (
    <Table
      columns={columns}
      data={data}
      title="Grupos de relatórios"
      size="small"
      canAdd
      addRoute={REPORTING_GROUPS_REGISTER_ROUTE}
      canEdit
      editRoute={REPORTING_GROUPS_EDIT_ROUTE}
      canRemove
      onRemove={onRemove}
    />
  );
}
