import React, { useCallback, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GetView, getView, getViewToken, View } from '@bcare-report/common';
import { Button } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import { useLoading } from '../../../contexts/loading.context';
import { VIEWS_ROUTE } from '../../../constants';
import { useStyles } from './styles';
import { useAuth } from '../../../contexts/auth.context';

export type TableauViewProps = {
  getTableauView: GetView;
};

export default function TableauView(): JSX.Element {
  const { showLoading, hideLoading } = useLoading();
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const { user } = useAuth();
  const [hashState, setHashState] = useState<string>('');
  const [viewUrl, setViewUrl] = useState<string>('');
  const classes = useStyles();

  function hex(buffer: any) {
    let digest = '';
    const view = new DataView(buffer);

    for (let i = 0; i < view.byteLength; i += 4) {
      const value = view.getUint32(i);
      const stringValue = value.toString(16);
      const padding = '00000000';
      const paddedValue = (padding + stringValue).slice(-padding.length);

      digest += paddedValue;
    }

    return digest;
  }

  function sha256(str: string) {
    const buffer = new TextEncoder().encode(str);

    return crypto.subtle.digest('SHA-256', buffer).then((hash) => hex(hash));
  }

  useEffect(() => {
    const fixedValue = '20150304';
    const splittedEmail = user.email.toUpperCase().split('@')[0];
    const utf8Value = unescape(encodeURIComponent(splittedEmail + fixedValue));

    sha256(utf8Value).then((digest) => {
      setHashState(`${'0X'}${digest.toUpperCase()}`);
    });
  }, []);

  useEffect(
    useCallback(() => {
      if (!id || !hashState) return;
      showLoading();
      (async () => {
        try {
          if (id) {
            const tableauUrl = await getViewToken.execute(id);
            if (tableauUrl !== '') {
              setViewUrl(`${tableauUrl.replace('<hash>', hashState)}`);
            } else {
              toast.error('Falha ao obter autenticação');
              push('/');
            }
          }
        } catch (err) {
          toast.error('Falha ao carregar view');
          console.log(err);
          push(VIEWS_ROUTE);
        } finally {
          hideLoading();
        }
      })();
    }, [id, hashState]),
    [id, hashState],
  );

  const handleClick = async () => {
    showLoading();
    try {
      const tableauUrl = await getViewToken.execute(id);
      if (tableauUrl !== '') {
        const url = `${tableauUrl.replace('<hash>', hashState)}`;
        const pdfUrl = `${url.split('?cod')[0]}${'.pdf'}`;
        window.open(pdfUrl, '_blank');
      } else {
        toast.error('Falha ao obter autenticação para exportar PDF');
      }
    } catch (err) {
      console.log(err);
      toast.error('Falha ao obter autenticação para exportar PDF');
    } finally {
      hideLoading();
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <Button
          startIcon={<GetApp />}
          onClick={handleClick}
          variant="contained"
          color="primary"
        >
        Exportar PDF
        </Button>
      </div>
      <iframe title="TableauView" src={viewUrl} width="100%" height="100%" />
    </div>
  );
}
