"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemoteExecution = void 0;
var RemoteExecution = /** @class */ (function () {
    function RemoteExecution(client) {
        this.client = client;
    }
    return RemoteExecution;
}());
exports.RemoteExecution = RemoteExecution;
