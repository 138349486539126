import React from 'react';
import { getView, getReportingGroups, registerView } from '@bcare-report/common';

import ViewRegister, { ViewRegisterProps } from '../../components/TableauView/Register';

const dispatchers: ViewRegisterProps = {
  getView,
  getReportingGroups,
  registerView,
};

export default function TableauViewRegisterComposer(): JSX.Element {
  return <ViewRegister {...dispatchers} />;
}
