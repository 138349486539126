import React from 'react';
import { getUser, getReportingGroups, registerUser } from '@bcare-report/common';

import UserRegister, { UserRegisterProps } from '../../components/User/Register';

const dispatchers: UserRegisterProps = {
  getUser,
  getReportingGroups,
  registerUser,
};

export default function UserRegisterComposer(): JSX.Element {
  return <UserRegister {...dispatchers} />;
}
