import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Checkbox, Grid, MenuItem, Paper, TextField } from '@material-ui/core';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import {
  extractValidationErrors,
  GetReportingGroups,
  GetDataSources,
  RegisterReport,
  ValidationError,
  reportRegisterForm,
  ReportGroup,
  DataSource,
  GetReport,
} from '@bcare-report/common';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '../../Select';
import { REPORTS_ROUTE } from '../../../constants';
import { useLoading } from '../../../contexts/loading.context';
import Button from '../../Button';
import Input from '../../Input';
import PageTitle from '../../PageTitle';

import { useStyles } from './styles';

export type ReportRegisterProps = {
  getReportingGroups: GetReportingGroups;
  getDataSources: GetDataSources;
  registerReport: RegisterReport;
  getReport: GetReport;
};

type ReportRegisterState = {
  reportingGroups: ReportGroup[];
  dataSources: DataSource[];
};

const defaultState: ReportRegisterState = {
  reportingGroups: [],
  dataSources: [],
};

export default function ReportRegister({ getDataSources, getReportingGroups, registerReport, getReport }: ReportRegisterProps): JSX.Element {
  const classes = useStyles();
  const formRef = useRef({} as any);
  const { isLoading, showLoading, hideLoading } = useLoading();
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const [state, setState] = useState<ReportRegisterState>(defaultState);
  const [reportingGroupsState, setReportingGroupsState] = useState<ReportGroup[]>([]);

  const handleGroupChange = (event: any, newValue: ReportGroup[]) => {
    setReportingGroupsState(newValue);
  };

  const handleSubmit = useCallback(
    (data) => {
      showLoading();
      async function runAsync() {
        try {
          formRef.current.setErrors({});
          await reportRegisterForm.validate(data, {
            abortEarly: false,
          });
          const reportingGroupsId = reportingGroupsState.map((x) => x.id);
          await registerReport.execute({
            id,
            reportingGroupsId,
            ...data,
          });

          toast.success('Registrado com sucesso');
          push(REPORTS_ROUTE);
        } catch (err) {
          console.log(err);
          let errors = {};
          if (err instanceof ValidationError) {
            errors = extractValidationErrors(err);
            formRef.current.setErrors(errors);
          } else {
            toast.error('Falha ao registrar relatório');
          }
        } finally {
          hideLoading();
        }
      }
      runAsync();
    },
    [id, reportingGroupsState],
  );

  useEffect(() => {
    showLoading();
    async function runAsync() {
      try {
        const dataSources = await getDataSources.execute();
        const reportingGroups = await getReportingGroups.execute();

        setState({
          ...state,
          dataSources,
          reportingGroups,
        });

        if (id) {
          const report = await getReport.execute(id);
          const dataSourceId = report.dataSource?.id;

          setReportingGroupsState(report.reportingGroups as ReportGroup[]);
          const fixData = {
            ...report,
            dataSourceId,
          };
          formRef.current.setData(fixData);
        }
      } catch (err) {
        toast.error('Falha ao carregar relatório');
        push(REPORTS_ROUTE);
      } finally {
        hideLoading();
      }
    }
    runAsync();
  }, [id]);

  return (
    <Box className={classes.container}>
      <PageTitle>{!id ? 'Cadastro de Relatório' : 'Editar Relatório'}</PageTitle>
      <br />
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Paper className={classes.container}>
          <Grid container spacing={2} direction="column">
            <Grid item xs={12} sm="auto">
              <Input
                name="name"
                variant="outlined"
                label="Nome do Relatório"
                className={classes.input}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={12} sm="auto">
              <Select
                label="Fonte de dados"
                variant="outlined"
                name="dataSourceId"
                value={state.dataSources}
                className={classes.input}
                fullWidth
                disabled={state.dataSources.length === 0 || isLoading}
              >
                {state.dataSources.map((dS) => (
                  <MenuItem key={dS.id} value={dS.id}>
                    {dS.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Autocomplete
                onChange={handleGroupChange}
                multiple
                value={reportingGroupsState}
                className={classes.input}
                options={state.reportingGroups}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                disableCloseOnSelect
                size="small"
                renderOption={(option, { selected }) => (
                  <>
                    <Checkbox
                      style={{ marginRight: 8, color: '#12C98A' }}
                      checked={selected}
                    />
                    {option.name}
                  </>
                )}
                renderInput={(params) => (
                  <TextField {...params} name="reportingGroups" required={reportingGroupsState.length < 1} variant="outlined" label="Grupos de relatórios" placeholder="Grupos" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm="auto">
              <Input
                name="query"
                multiline
                rows={20}
                variant="outlined"
                label="Query"
                fullWidth
                disabled={isLoading}
              />
            </Grid>
            <Grid item>
              <Button type="submit" variant="contained" color="primary">
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Form>
    </Box>
  );
}
