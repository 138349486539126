import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { toast } from 'react-toastify';
import { GetDataSources, DeleteDataSource, DataSource } from '@bcare-report/common';

import { useLoading } from '../../../contexts/loading.context';
import DataSourceTable from '../../DataSourceTable';

import { useStyles } from './styles';

export type DataSourceListProps = {
  getDataSources: GetDataSources;
  deleteDataSource: DeleteDataSource
};

type DataSourceListState = {
  dataSources: DataSource[];
};

const defaultState: DataSourceListState = {
  dataSources: [],
};

export default function DataSourceList({ getDataSources, deleteDataSource }: DataSourceListProps): JSX.Element {
  const classes = useStyles();
  const [state, setState] = useState<DataSourceListState>(defaultState);
  const { showLoading, hideLoading, showBackdrop, hideBackdrop } = useLoading();

  const handleRemoveClick = useCallback((dataSource: DataSource) => {
    showLoading();
    (async () => {
      try {
        await deleteDataSource.execute(dataSource.id);
        toast.success('Fonte de dados removida com sucesso', {
          onOpen: showBackdrop,
          onClose: hideBackdrop,
        });
        const dataSources = await getDataSources.execute();
        setState({
          dataSources,
        });
      } catch (err) {
        console.log(err);
        toast.warning('Falha ao apagar fonte de dados', {
          onOpen: showBackdrop,
          onClose: hideBackdrop,
        });
      } finally {
        hideLoading();
      }
    })();
  }, []);

  useEffect(() => {
    showLoading();
    async function runAsync() {
      try {
        const dataSources = await getDataSources.execute();
        setState({
          dataSources,
        });
      } catch (err) {
        console.log(err);
      } finally {
        hideLoading();
      }
    }
    runAsync();
  }, []);

  return (
    <Box className={classes.container}>
      <DataSourceTable data={state.dataSources} onRemove={handleRemoveClick} />
    </Box>
  );
}
