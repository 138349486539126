import React from 'react';
import { DataSource } from '@bcare-report/common';

import { DATA_SOURCES_REGISTER_ROUTE, DATA_SOURCES_EDIT_ROUTE } from '../../constants';
import Table from '../Table';

const columns = [
  {
    key: 'name',
    label: 'Nome',
  },
  {
    key: 'dataSourceType',
    label: 'Tipo de conexão',
    render: function DataSourceTypeRender(value: string) {
      if (value === 'SQLSERVER') {
        return 'Sql Server';
      }
      return 'Athena';
    },
  },
];

export type DataSourceTableProps = {
  data: DataSource[];
  onRemove: (group: DataSource) => void;
};

export default function DataSourceTable({ data, onRemove }: DataSourceTableProps): JSX.Element {
  return (
    <Table
      columns={columns}
      data={data}
      title="Fontes de dados"
      size="small"
      canAdd
      addRoute={DATA_SOURCES_REGISTER_ROUTE}
      canEdit
      editRoute={DATA_SOURCES_EDIT_ROUTE}
      canRemove
      onRemove={onRemove}
    />
  );
}
