import React from 'react';
import { ThemeProvider, CssBaseline, makeStyles } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import common from '@bcare-report/common';
import 'react-toastify/dist/ReactToastify.css';

import light from './themes/light';
import Routes from './routes';
import { LoadingProvider } from './contexts/loading.context';

const useStyles = makeStyles({
  app: {
    background: '#fff',
    height: '100vh',
  },
});

function App(): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.app}>
      <ThemeProvider theme={light}>
        <ToastContainer
          position="top-right"
          autoClose={4000}
          closeOnClick
          draggable
          limit={1}
          newestOnTop
          hideProgressBar={false}
        />
        <CssBaseline />
        <LoadingProvider>
          <Routes />
        </LoadingProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
