import React, { useCallback } from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { GetApp } from '@material-ui/icons';
import { toast } from 'react-toastify';
import Button from '../Button';
import { useLoading } from '../../contexts/loading.context';

type ExportToExcelProps = {
  data: any,
  fileName: string,
}

export default function ExportToExcel({ data, fileName }: ExportToExcelProps): JSX.Element {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const formatedName = fileName.replaceAll(' ', '_');
  const { showBackdrop, hideBackdrop } = useLoading();

  const exportToExcel = useCallback(() => {
    toast.warning('Exportando arquivo, por favor aguarde', {
      onOpen: showBackdrop,
      onClose: hideBackdrop,
    });

    setTimeout(() => {
      try {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(dataBlob, formatedName + fileExtension);
      } catch (error) {
        toast.error('Falha ao exportar relatório');
        console.log(error);
      }
    }, 300);
  }, [data]);

  return (
    <Button
      startIcon={<GetApp />}
      onClick={exportToExcel}
      variant="contained"
      color="primary"
    >
      Exportar Excel
    </Button>
  );
}
