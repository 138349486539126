import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { toast } from 'react-toastify';
import { GetViews, DeleteView, View } from '@bcare-report/common';
import ViewTable from '../../ViewTable';

import { useLoading } from '../../../contexts/loading.context';

import { useStyles } from './styles';

export type ViewListProps = {
  getViews: GetViews;
  deleteView: DeleteView
};

type ViewListState = {
  views: View[];
};

const defaultState: ViewListState = {
  views: [],
};

export default function ViewList({ getViews, deleteView }: ViewListProps): JSX.Element {
  const classes = useStyles();
  const [state, setState] = useState<ViewListState>(defaultState);
  const { showLoading, hideLoading } = useLoading();

  const handleRemoveClick = useCallback((view: View) => {
    showLoading();
    (async () => {
      try {
        await deleteView.execute(view.id);
        toast.success('View removida com sucesso');
        const views = await getViews.execute();
        setState({
          views,
        });
      } catch (err) {
        console.log(err);
        toast.warning('Falha ao excluir View');
      } finally {
        hideLoading();
      }
    })();
  }, []);

  useEffect(() => {
    showLoading();
    async function runAsync() {
      try {
        const views = await getViews.execute();
        setState({
          views,
        });
      } catch (err) {
        console.log(err);
      } finally {
        hideLoading();
      }
    }
    runAsync();
  }, []);

  return (
    <Box className={classes.container}>
      <ViewTable data={state.views} onRemove={handleRemoveClick} />
    </Box>
  );
}
